import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function AboutUs() {
  return (
    <div className="bg-brand-green">
      <div className="mx-auto py-16 px-4 sm:px-6 md:max-w-2xl md:px-2 lg:max-w-5xl lg:py-12 lg:px-8 xl:max-w-7xl">
        <div className=" flex flex-col lg:grid lg:grid-cols-3 lg:gap-8">
          <div className=" mt-10 lg:col-span-2 lg:mt-0">
            <h2 className="text-3xl font-semibold tracking-tight text-white">
              Kate Burton
            </h2>
            <h3 className="pb-2 text-base tracking-tight text-white">Owner</h3>
            <p className="pb-4 text-xl font-normal text-white">
              Kate Burton, founded Home Safe in 2019 after having friends and
              family be denied home insurance coverage while on vacation or in
              hospital care because no one was checking on their home regularly.
            </p>
            <p className="pb-4 text-xl font-normal text-white">
              She's on a mission to educate and protect homeowners from denied
              claims in the future. Kate is bonded and insured to provide you
              with extra piece of mind.
            </p>
          </div>
          <div className="order-first lg:order-last">
            <StaticImage
              src="../images/kate.jpg"
              className="mr-auto flex w-1/2 rounded-full object-cover sm:w-2/5 md:w-2/5 lg:mx-auto lg:w-3/4"
              alt="Kate Burton Owner of HomeSafe"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
