import { CheckIcon, PlusIcon } from '@heroicons/react/20/solid';

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Addons from './addons';

const tiers = [
  {
    name: 'Bronze',
    href: '#',
    priceWeekly: 30,

    extraFeatures: [
      'Full check of the house (includes windows & doors)',
      'Check outer perimeter and exterior structures',
      'Check for water leakage',
    ],
    includedFeatures: ['One visit/week'],
  },
  {
    name: 'Silver',
    href: '#',
    priceWeekly: 55,

    extraFeatures: [
      'Full check of the house (includes windows & doors)',
      'Check outer perimeter and exterior structures',
      'Check for water leakage',
    ],
    includedFeatures: ['Two visit/week'],
  },
  {
    name: 'Gold',
    href: '#',
    priceWeekly: 80,
    extraFeatures: [
      'Full check of the house (includes windows & doors)',
      'Check outer perimeter and exterior structures',
      'Check for water leakage',
    ],
    includedFeatures: [
      'Three visits/week',
      'Full report emailed after scheduled visit',
    ],
  },
  {
    name: 'Platinum',
    href: '#',
    priceWeekly: 125,
    extraFeatures: [
      'Full check of the house (includes windows & doors)',
      'Check outer perimeter and exterior structures',
      'Check for water leakage',
    ],
    includedFeatures: [
      'Five visits / week',
      'Full report emailed after scheduled visit',
      'Free grocery delivery included  (groceries not included)',
    ],
  },
];

export default function ServiceLevels() {
  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-7xl px-4 pt-2 sm:px-6 lg:px-8">
        <div className="sm:align-center sm:flex sm:flex-col">
          <div className="!block">
            <StaticImage
              src="../images/lock-sm.png"
              className="mx-auto mt-10 flex w-16 justify-center text-center"
              alt="Fully Insured and Bonded"
              loading="lazy"
            />
          </div>
          <h3 className="font-base mx-auto flex justify-center pb-4 text-center text-2xl tracking-tight text-gray-900">
            Fully Insured and Bonded
          </h3>
          <h1 className="text-center text-5xl font-bold tracking-tight text-gray-900">
            Pricing Packages
          </h1>
          <p className="mt-5 text-xl text-gray-500 sm:text-center">
            We have package options and custom add on services to meet your
            needs.
          </p>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4 ">
          {tiers.map((tier) => (
            <div
              key={tier.name}
              className="divide-y divide-gray-200 rounded-sm border border-gray-200 shadow-lg "
            >
              <div className="bg-white p-6">
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  {tier.name}
                </h2>

                <p className="mt-8">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    ${tier.priceWeekly}+
                  </span>{' '}
                  <span className="text-base font-medium text-gray-500">
                    /week
                  </span>
                </p>
              </div>
              <div className="px-6 pt-6 pb-8">
                <h3 className="text-sm font-medium text-gray-900">Includes:</h3>
                <ul className="mt-6 space-y-4">
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon
                        className="h-5 w-5 flex-shrink-0 text-brand-blue"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
              {/* <div className="px-6 pt-6 pb-8">
                <h3 className="text-sm font-medium text-gray-900">Plan:</h3>
                <ul className="mt-6 space-y-4">
                  {tier.extraFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <PlusIcon
                        className="h-5 w-5 flex-shrink-0 text-brand-blue"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>
          ))}
        </div>
      </div>
      <Addons />
    </div>
  );
}
