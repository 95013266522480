import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import {
  ArrowPathIcon,
  Bars3Icon,
  BookmarkSquareIcon,
  CalendarIcon,
  ChartBarIcon,
  CheckIcon,
  CursorArrowRaysIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const features = [
  'Four visits / week',
  'Check for water leakage',
  'Full report emailed after scheduled visit',
  'Free grocery delivery included  (groceries not included)',
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SnowBird() {
  return (
    <div className="bg-white">
      <div className="bg-gradient-to-b from-blue-50 via-white to-white">
        {/* Pricing section with single price and feature list */}
        <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="border-b border-gray-200 pb-16 xl:grid xl:grid-cols-3 xl:gap-x-8">
            <div>
              <h2 className="text-lg font-semibold text-blue-600">$100/week</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
                Snowbird Special
              </p>
              <p className="mt-4 text-lg text-gray-500">
                Book more than a month at the same time and save.
              </p>
            </div>
            <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:col-span-2 xl:mt-0">
              <ul role="list" className="divide-y divide-gray-200">
                {features.slice(0, 3).map((feature, featureIdx) => (
                  <li
                    key={feature}
                    className={classNames(
                      featureIdx === 0 ? 'md:py-0 md:pb-4' : '',
                      'flex py-4'
                    )}
                  >
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 text-brand-blue"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">
                      {feature}
                    </span>
                  </li>
                ))}
              </ul>
              <ul
                role="list"
                className="divide-y divide-gray-200 border-t border-gray-200 md:border-t-0"
              >
                {features.slice(3).map((feature, featureIdx) => (
                  <li
                    key={feature}
                    className={classNames(
                      featureIdx === 0 ? 'md:border-t-0 md:py-0 md:pb-4' : '',
                      'flex py-4'
                    )}
                  >
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 text-brand-blue"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">
                      {feature}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
