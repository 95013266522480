import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

const includedFeatures = [
  'Plant Care',
  'Animal Care',
  'Dog Sitter Service',
  'Front Garden(light weeding)',
  'Light housekeeping',
  'Grocery Pick-up & Delivery',
  'Extra visits',
];

export default function Addons() {
  return (
    <div className="bg-gray-50">
      <div className="mt-8  pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
        <div className="relative">
          <div className="relative mx-auto max-w-7xl  px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-lg overflow-hidden rounded-sm border-2 shadow-lg lg:flex lg:max-w-none">
              <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                <div className="">
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 bg-white pr-4 text-2xl font-bold text-brand-blue">
                      Add Ons
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200" />
                  </div>
                  <ul className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                    {includedFeatures.map((feature) => (
                      <li
                        key={feature}
                        className="flex items-start lg:col-span-1"
                      >
                        <div className="flex-shrink-0">
                          <CheckCircleIcon
                            className="h-5 w-5 text-brand-green"
                            aria-hidden="true"
                          />
                        </div>
                        <p className="ml-3 text-base text-gray-700">
                          {feature}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="bg-gray-50 py-8 px-6 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12">
                <p className="text-lg font-medium leading-6 text-gray-900">
                  Reach out to Kate
                </p>
                <p className="mt-2 text-sm">Learn more about add on details</p>

                <div className="mt-6">
                  <div className="rounded-md shadow">
                    <a
                      href="#contact"
                      className="flex items-center justify-center rounded-md border border-transparent bg-brand-blue px-5 py-3 text-base font-medium text-white hover:bg-gray-900"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
