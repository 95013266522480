import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import AboutUs from '../components/aboutUs';
import DidYouKnow from '../components/didYouKnow';
import Contact from '../components/contact';
import ServiceLevels from '../components/serviceLevels';
import SnowBird from '../components/snowbird';
import Header from '../components/header';
import HeroImage from '../components/heroImage';
import LogoBar from '../components/logobar';
import CTA from '../components/cta';

function Index() {
  return (
    <Layout>
      <Seo title="House and Pet Care Services" />

      <LogoBar slogan="Trusted & Reliable Pet & House Care Services" />
      <HeroImage />

      <SnowBird />
      <ServiceLevels />
      <DidYouKnow
        title="Did you know...?"
        text="An empty home can void your home insurance policy even if you've
            paid your premiums?"
      />

      <AboutUs />
      <Contact />
    </Layout>
  );
}

export default Index;
